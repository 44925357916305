import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { scope: String }

  selectAll(event) {
    event.preventDefault();
    document
      .querySelectorAll(`.${this.scopeValue}-list-item`)
      .forEach((i) => i.listItem.select());
  }

  deselectAll(event) {
    event.preventDefault();
    document
      .querySelectorAll(`.${this.scopeValue}-list-item`)
      .forEach((i) => i.listItem.deselect());
  }
}
