import Flatpickr from "stimulus-flatpickr";

export default class extends Flatpickr {
  connect() {
    this.config = {
      ...this.config, //spread options in case some where defined in initialize
      dateFormat: "Z",
      altFormat: "m/d/Y",
      altInput: true,
      allowInput: true,
    };

    super.connect();
  }
}
