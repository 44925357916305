import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["item", "selectedIcon", "unselectedIcon"];
  static values = {
    selected: Boolean,
    userId: String,
    userName: String,
  };

  connect() {
    // taken from https://leastbad.com/stimulus-power-move
    // allows to query the DOM from parent controllers for all
    // instances of this controller and call actions on it
    this.element["listItem"] = this;
    this.selectedValue = false;
  }

  disconnect() {
    this.selectedValue = false;
  }

  toggle(event) {
    event.preventDefault();
    this.selectedValue = !this.selectedValue;
  }

  selectedValueChanged() {
    if (this.selectedValue) {
      this.itemTarget.classList.add("list-group-item-info");
      this.selectedIconTarget.classList.remove("d-none");
      this.unselectedIconTarget.classList.add("d-none");
      this.dispatch("selected", {
        userId: this.userIdValue,
        userName: this.userNameValue
      });
    } else {
      this.itemTarget.classList.remove("list-group-item-info");
      this.unselectedIconTarget.classList.remove("d-none");
      this.selectedIconTarget.classList.add("d-none");
      this.dispatch("deselected", {
        userId: this.userIdValue,
        userName: this.userNameValue
      });
    }
  }

  select() {
    this.selectedValue = true;
  }

  deselect() {
    this.selectedValue = false;
  }
}
