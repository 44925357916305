import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "expandedIcon", "collapsedIcon"];
  static classes = ["hidden"];
  static values = { expanded: Boolean };

  connect() {
    // taken from https://leastbad.com/stimulus-power-move
    // allows to query the DOM from parent controllers for all
    // instances of this controller and call actions on it
    this.element["accordianItem"] = this;
  }

  toggle(event) {
    event.preventDefault();
    this.expandedValue = !this.expandedValue;
  }

  expandedValueChanged() {
    if (this.expandedValue) {
      this.contentTargets.forEach((t) => t.classList.remove(this.hiddenClass));
      this.expandedIconTarget.classList.remove("d-none");
      this.collapsedIconTarget.classList.add("d-none");
    } else {
      this.contentTargets.forEach((t) => t.classList.add(this.hiddenClass));
      this.collapsedIconTarget.classList.remove("d-none");
      this.expandedIconTarget.classList.add("d-none");
    }
  }

  expand() {
    this.expandedValue = true;
  }

  collapse() {
    this.expandedValue = false;
  }
}
