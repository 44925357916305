import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = { url: String };

  async connect() {
    let response = await get(this.urlValue);

    if (response.ok) {
      let json = await response.json;
      window.location.replace(json.url);
    }
  }
}
