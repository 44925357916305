import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["selectedCount"];

  static values = {
    selectedCount: Number,
    userIds: Array,
    userNames: Array,
  };

  connect() {
    this.selectedCountValue = 0;
    this.userIdsValue = [];
    this.userNamesValue = [];
  }

  disconnect() {
    this.selectedCountValue = 0;
    this.userIdsValue = [];
    this.userNamesValue = [];
  }

  increment(event) {
    event.stopPropagation();

    this.userIdsValue = [...this.userIdsValue, event.detail.userId];
    this.userNamesValue = [...this.userNamesValue, event.detail.userName];

    this.selectedCountValue++;
  }

  decrement(event) {
    event.preventDefault();

    this.userIdsValue = this.userIdsValue.filter(
      (n) => n !== event.detail.userId
    );
    this.userNamesValue = this.userNamesValue.filter(
      (n) => n !== event.detail.userName
    );

    if (this.selectedCountValue > 0) {
      this.selectedCountValue--;
    }
  }

  selectedCountValueChanged() {
    this.selectedCountTargets.forEach(
      (t) => (t.innerHTML = this.selectedCountValue)
    );
  }

  previewEmail(event) {
    event.preventDefault();
    if (this.userIdsValue.length > 0) {
      let userIds = this.userIdsValue.join(",");
      window.location = `${event.target.href}?user_ids=${userIds}`;
    } else {
      alert("You must select players before sending an email.");
    }
  }

  changeAvailability(event) {
    if (this.userNamesValue.length > 0) {
      let names = this.userNamesValue.join(", ");
      let availability = event.target.querySelector("#availability").value;
      let message = `This will change ${names} availability to ${availability}.`;
      if (window.confirm(message)) {
        let userIds = this.userIdsValue.join(",");
        event.target.querySelector("#user_ids").value = userIds;
      } else {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
      alert("You must select players before changing availability.");
    }
  }
}
