import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  expandAll(event) {
    event.preventDefault();
    document
      .querySelectorAll(".accordian-item")
      .forEach((a) => a.accordianItem.expand());
  }

  collapseAll(event) {
    event.preventDefault();
    document
      .querySelectorAll(".accordian-item")
      .forEach((a) => a.accordianItem.collapse());
  }
}
