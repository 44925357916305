import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];
  static classes = ["hidden"];

  toggle(event) {
    event.preventDefault();
    let hiddenClass = this.hasHiddenClass ? this.hiddenClass : "d-none"
    this.contentTargets.forEach((t) => t.classList.toggle(hiddenClass));
  }
}
